import React, { Component } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { AUTH_USER_TOKEN_KEY, USERNAME, MENU_COLLAPSED_FLAG} from '../../Utils/constants';

import { Auth } from 'aws-amplify';
import { ClickParam } from 'antd/lib/menu';
import { Layout, Menu, Icon, notification } from 'antd';
import { colors } from '../../Themes/Colors';

import { BaseState } from './BaseState';
import { DateRange } from "materialui-daterange-picker";
import DateTimeRangePicker from "../ModalContainer/DateTimeRangePicker"

interface ParamsBaseComponent extends RouteComponentProps{
  displayTimeDateRange?:boolean,
  onDateChange?: (range: DateRange) => void
}

class BaseComponent extends Component<ParamsBaseComponent, BaseState>{

    constructor(props:ParamsBaseComponent) {
        super(props);
        
        this.state = {
            collapsed:localStorage.getItem(MENU_COLLAPSED_FLAG)==='T',
            username:'<unknown>',
            dateRange:{}
           }
    }

    componentDidMount() {
        let token = localStorage.getItem(AUTH_USER_TOKEN_KEY);
        
        if(token== null){            
          this.props.history.push('./login');
        }        
        //console.log("componentDidMount");
    }

    onDateChange(range:DateRange) {
      //console.log("set date range:" , range);
      //this.setState({dateRange:range
      // if setup call the onDateChange listener 
      if(this.props.onDateChange){
        this.props.onDateChange(range);
      }
    }

    render(){
        const handleLogout = async (event: ClickParam) => {
            
            try {
              await Auth.signOut({ global: true }).then(() => {
                localStorage.removeItem(AUTH_USER_TOKEN_KEY);
                this.props.history.push('./login');
              });
            } catch (err) {
              //notification.error({ message: err.message });
              this.props.history.push('./login');
            }
          };
          const handleSettings = async (event: ClickParam) => {
            //console.dir(event);
            this.props.history.push('/dashboard');
          };
          //console.log("rander base component");
        return (            
            <Layout className="cover" id="app-header">
              
              <Layout.Sider className="cover" trigger={null} collapsible collapsed={this.state.collapsed}>
                <div className="logo" />
                <Menu theme="dark" mode="inline" defaultSelectedKeys={['1']}>
                  <Menu.Item key="1" onClick={event => handleSettings(event)}>
                    <Icon type="home" />
                    <span>Home</span>
                  </Menu.Item>
                  <Menu.Item key="2" onClick={event => handleSettings(event)}>
                    <Icon type="setting" />
                    <span>Settings</span>
                  </Menu.Item>
                  <Menu.Item key="3" onClick={event => handleLogout(event)}>
                    <Icon type="logout" />
                    <span>Logout</span>
                  </Menu.Item>
                </Menu>
              </Layout.Sider>
              <Layout>
                <Layout.Header style={{ background: colors.white, padding: 0 }}>
                <div style={{float: "left"}}>
                  <Icon
                    className="trigger"
                    onClick={() => {
                      this.setState({ collapsed: !this.state.collapsed })
                      const collapsedFlag=this.state.collapsed?'F':'T';
                      localStorage.setItem(MENU_COLLAPSED_FLAG,collapsedFlag)
                    }}
                    type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
                  />
                  </div>
                  <div style={{float: "right",marginRight:'20px'}}>
                  <h1>{localStorage.getItem(USERNAME)}</h1>
                  </div>
                  <div style={{float: "right",marginRight:'20px'}}>
                    {this.props.displayTimeDateRange || typeof(this.props.displayTimeDateRange)=='undefined'?
                    (<DateTimeRangePicker {...this.props} 
                      onChange={this.onDateChange.bind(this)}
                    />):("")}
                  </div>
                </Layout.Header>
                <Layout.Content 
                    style={{                      
                      padding: 24,
                      background: colors.white,
                      minHeight: 280
                  }}
                  >
                {this.props.children}
                </Layout.Content>
              </Layout>
            </Layout>
        )
    }
}
//margin: '24px 16px',
export default withRouter(BaseComponent);

