
import * as React from 'react';
import { Link, Redirect} from 'react-router-dom';
import { Button} from 'antd';

/**
 * This utility allows passing a javascript object from java (android) that 
 * changes the login behavior as follow:
 *   - if the javascript object is defined, it will call the method closeActivity() that closes the webview
 *   - otherwise, it will redirect to the user back to the normal login flow
 */

interface WebAppInterface {
    closeActivity():any;
    getContext():any;
  }
declare var android: WebAppInterface;
  
export function renderLogin(context: string, message: string){
    if(typeof android == 'object' && android.getContext()==="android" && context && context.includes("&context=android")){
        return(<Button style={{borderStyle:"none",margin:'0px',padding:'0px', color:'#1890ff'}} onClick={()=>{android.closeActivity()}}>{message}</Button>);
    }
    //else
    return(<Link to="/login">{message}</Link>);    
}
  
export function redirectLogin(context:string){    
    if(typeof android !== undefined && android.getContext()==="android" && context && context.includes("&context=android")){
        return android.closeActivity();
    }
    //else
    return (<Redirect to={{ pathname: '/login' }} />);
}