import React, { Component} from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {DeviceType, GPSSessionData, GPSDeviceData,SensorData} from '../../Data/Devices'
import {DateTimeUtils} from '../../Utils/DateTimeUtils';
import {UnitsHelper} from '../../Utils/Helpers';
import CSS from 'csstype';
import {GPSRenderer} from '../../Utils/GPSRenderer';
import Pagination from '@mui/material/Pagination';
import SensorIcon  from "./ViewComponent/SensorIcon";
import ToggleButton from '@material-ui/lab/ToggleButton';

interface DeviceProps{
    device?: DeviceType[],    
    sensorData?:any,
    metaData?:any
}

const paginationStyles: CSS.Properties = {
    padding:'10px', 
    justifyContent: "center", 
    display:"flex", 
    alignItems:"center", 
    flex:"1"
}
interface RowSetting{
    showMap:boolean
}

interface SessionsViewState{
    page : number,
    pageSize: number,
    tableSettings:any, 
    rowSettings:Map<string,RowSetting>
}

export default  class SessionsViewComponent extends Component<DeviceProps, SessionsViewState>{

    constructor(props:DeviceProps){
        super(props);
        this.state={
           page:1,
           pageSize:25,
           tableSettings:{showMap:false},
           rowSettings:new Map<string,RowSetting>()
        }
        this.handleChangePage = this.handleChangePage.bind(this);
    }
    
    private getGPSDeviceData(devices:DeviceType[], sessionsMetaData:GPSSessionData[], sensorsData:any){
        // prep devices data
        let totalSessions = 0;
        let hashMap = new Map<string, GPSDeviceData>();
        let allSessions: GPSSessionData[]= [];
        devices.forEach(elem =>{
            hashMap.set(elem.duid, {did:elem.duid,sessions:[], sessionsMap:new Map<string, GPSSessionData>()});
        });

        sessionsMetaData.forEach(elem=>{
            let device:GPSDeviceData|undefined = hashMap.get(elem.did);
            if(device && device != undefined){
                elem.points = [];
                device.sessions.push(elem);
                allSessions.push(elem);
                device.sessionsMap.set(""+elem.id, elem);
                totalSessions=totalSessions+1;
            }
        });
        
        hashMap.forEach(elem =>{
            if(!sensorsData.hasOwnProperty(elem.did)){                
                return; // continue
            }
            let gpsPoints:SensorData[] = sensorsData[elem.did].GPS[0];
            if(! gpsPoints || gpsPoints===undefined){
                return; // continue
            }
            gpsPoints.forEach(sensRow=>{
                let sessionMap:GPSSessionData|undefined = elem.sessionsMap.get(""+sensRow.vals[5]);
                if(sessionMap===undefined){
                    return; // continue
                }
                sessionMap.points.push(sensRow);
            })
        });
        return {totalSessions:totalSessions, arrDevices:Array.from(hashMap.values()), allSessions:allSessions};
    }

    private handleChangePage(event: React.ChangeEvent<unknown>, value: number){
        //console.log("page change: ", value);
        this.setState({page:value});
    }

    renderButtonGPS(size:number, id:string, rowState:RowSetting){
        return (
                <ToggleButton style={{marginRight: '10px', color:'#7393B3', width:size+"px", height:size+"px"}}  
                    value="check" 
                    selected={rowState.showMap}
                    onChange={() => {
                        rowState.showMap = !rowState.showMap;
                        this.state.rowSettings.set(id, rowState);
                        this.setState({rowSettings:this.state.rowSettings})
                    }}
                >
                <SensorIcon sensType="GPS"/>
                </ToggleButton>
        );
    }

    renderMapCell(row:GPSSessionData){
        let id = row.did + "." + row.id;
        let rowState = this.state.rowSettings.get(id);
        let showMap:boolean = this.state.tableSettings.hasOwnProperty("showMap") && this.state.tableSettings.showMap; 
        if(!rowState){
            rowState = {showMap:false};
        }else{
            showMap = rowState.showMap;
        }

        if(showMap){
            rowState.showMap=true;
            return(
            <div style={{height:"175px", width:"300px", float:"right", position: "relative"}}>                
                <GPSRenderer sessions={new Array<GPSSessionData>(row)} />                
                <div style={{float:"right", position:"absolute", top:"10px", right:"0px"}}>
                    {this.renderButtonGPS(30, id, rowState)}
                </div>
            </div>
            );
        }else{
            return(          
            <div style={{float:"right"}}>
                {this.renderButtonGPS(30, id, rowState)}
            </div>
            );
        }
    }

    render(){    
        let gpsDeviceData = this.getGPSDeviceData(this.props.device?this.props.device:[], 
                                     this.props.metaData?this.props.metaData.GPS:[], 
                                     this.props.sensorData?this.props.sensorData:[]);
        let totPages = Math.ceil(gpsDeviceData.totalSessions / this.state.pageSize);

        let startIndex = (this.state.page-1)*this.state.pageSize;
        if(startIndex > gpsDeviceData.allSessions.length){
            startIndex = 0;
            this.setState({page:1});
        }
        let allSessions = gpsDeviceData.allSessions.slice(
                    startIndex, 
                    Math.min(gpsDeviceData.allSessions.length,startIndex+this.state.pageSize));

        return  (
            <Paper>
            <TableContainer >
            <Table  aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell>Start At</TableCell>
                    <TableCell>{UnitsHelper.getDistanceUnit()}</TableCell>
                    <TableCell>Duration</TableCell>
                    <TableCell> 
                    <div style={{float:"right"}}>
                        <ToggleButton style={{marginRight: '0px', color:'blue', border: "1px solid blue"}}  
                            value="check" 
                            selected={this.state.tableSettings.showMap}
                            onChange={() => {this.state.rowSettings.clear();this.setState({tableSettings:{showMap:!this.state.tableSettings.showMap}})}}
                        >
                            <SensorIcon sensType="GPS"/>
                        </ToggleButton>
                    </div>

                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {
                    allSessions.map((row) => (
                    <TableRow key={row.startime}>
                        <TableCell>
                            {DateTimeUtils.formatDateTime(row.startime)}
                        </TableCell>
                        <TableCell>
                            {UnitsHelper.formatDistance(row.meters)}
                        </TableCell>
                        <TableCell>
                            {new Date(Number(row.duratsec) * Number(1000)).toISOString().substring(11, 19)}
                        </TableCell>
                        <TableCell>
                            {this.renderMapCell(row)}
                        </TableCell>
                    </TableRow>
              ))
            }
            </TableBody>
            </Table>
            </TableContainer>
            <Pagination style={paginationStyles} count={totPages} page={this.state.page} onChange={this.handleChangePage} />
            </Paper>
        )
        //  this.map = map; this.maps = maps
       }
}
