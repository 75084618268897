import * as React from 'react';

/** Components */
import AppRouter from '../Navigation/Routes';

// Import the functions you need from the SDKs you need

/** Ant design stylesheet */
import 'antd/dist/antd.css';
import "./styles.css"


const App: React.FC = () => {
  return (
      <AppRouter />
  );
};

export default App;
