
import React from "react";
import Button from '@mui/material/Button';
//import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

export interface ConfirmDialogProps{
    title: string,
    message: string|null,
    open: boolean|false
    onConfirm:any,
    onCancel:any
}

export interface ConfirmDialogState{
    open: boolean,
    title:string,
    message: string|null,
}

export default class ConfirmDialog extends React.Component<ConfirmDialogProps, ConfirmDialogState> {

    constructor(props:ConfirmDialogProps) {
        super(props)
        this.state={
            open:props.open,
            title:props.title,
            message:props.message,
        }
        this.props.onConfirm.bind(this);
        this.props.onCancel.bind(this);
    }

    openDialog(message: string){
        this.setState({open : true})
    }

    static getDerivedStateFromProps(props: ConfirmDialogProps, state : ConfirmDialogState) {
        return { open: props.open, message:props.message};
    }

    render(){
        return (
            <Dialog
                open={this.state.open}
                onClose={() => {
                    this.props.onCancel();}
                }
                aria-labelledby="confirm-dialog"
            >
            <DialogTitle id="confirm-dialog">{this.props.title}</DialogTitle>
            <DialogContent>
                {this.state.message!=null?this.state.message:":==: "}
                {this.props.children}
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    onClick={() => {
                            this.props.onCancel();
                        }
                    }
                >
                Cancel
                </Button>
                <Button
                    variant="contained"
                    onClick={() => {
                            this.props.onConfirm();
                        }
                    }
                    color="secondary"
                >
                Yes
                </Button>
            </DialogActions>
            </Dialog>
        );
    }
}
