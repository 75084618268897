import React, { Component } from 'react'
import GPS from '@material-ui/icons/LocationOnOutlined';
import VideoIcon from '@material-ui/icons/VideocamOutlined';
import DefaultIcon from '@material-ui/icons/DeviceUnknown';//SelectAllOutlined';
import DeviceThermostat from '@mui/icons-material/ThermostatOutlined';


interface IconState{
    sensType:string;
}

export default  class SensorIcon extends Component<IconState>{

    sensType :string;

    constructor(iconState:IconState) {
        super(iconState);
        this.sensType = iconState.sensType;
    }
    
    render(){    
        if(this.sensType === 'Video'){
            return  (<VideoIcon/>)
        }else if(this.sensType === 'GPS'){
            return  (<GPS/>)
        }else if(this.sensType === 'Temp'){
            return  (<DeviceThermostat/>)
        }
        else{
            return  (<DefaultIcon/>)
        }
    }
}
