import React, { Component } from 'react'

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import {Sensor, DeviceType} from '../../Data/Devices'
import TableRow from '@material-ui/core/TableRow';
import {DEVICE_TYPE}  from '../../Utils/constants';
import {DateTimeUtils} from '../../Utils/DateTimeUtils';
import ToggleButton from '@material-ui/lab/ToggleButton';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { actions} from '../../Utils/constants';
import api  from '../../Utils/Helpers';
import {VideoViewer} from '../DashboardContainer/VideoViewer'
import { Link } from 'react-router-dom'
import BaseComponent from '../BaseComponentContainer/BaseComponent'
import ExpandMore  from "@material-ui/icons/ExpandMore";
import SensorIcon  from "./ViewComponent/SensorIcon";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Menu, MenuItem } from "@material-ui/core";
import { notification } from 'antd';
import ConfirmDialog from '../../Utils/ConfirmDialog';

interface ViewState {
    devices: DeviceType[]
    selectedSate: boolean[]
    anchorEl: null|HTMLElement
    menuDUID: null|string
    confirmDialogState:boolean
}


export type MenuCloseHandler = () => void;


export default  class DeviceListViewComponent<RouteComponentProps> extends Component<RouteComponentProps, ViewState>{


    constructor(props:RouteComponentProps) {
        super(props)
 
        this.state = {
         devices : [],
         selectedSate:[],
         anchorEl:null,
         menuDUID:null,
         confirmDialogState:false
        }
        this._onMenuClose = this._onMenuClose.bind(this);
        this.removeDevice = this.removeDevice.bind(this);
     }
    
    private _onOpenMenu(event: React.MouseEvent<HTMLButtonElement>, duid:string) {      
      this.setState({ anchorEl: event.currentTarget, menuDUID: duid, confirmDialogState:false });
    }
    
    private removeDevice(){
      if(this.state.menuDUID==null){
        return;
      }
      //console.log("delete: " + this.state.menuDUID);
      this.setState({confirmDialogState : false});
      let thisInstance = this;
      let params = {did:this.state.menuDUID};
      api.backendCall({action:actions.ACTION_UNREGISTER_DEVICE,params:params})
      .then((response)=>{
          //console.dir(response);
          if(response.status===200 && response.data.error===""){
            notification.success({
              message: 'Successfully removed device and all its sensor data',
              description: 'Operation completed!',
              placement: 'topRight',
              duration: 2.0,
            });

            thisInstance.getDevices();
          }
      })
      .catch((error) => {api.errorAlert(error);}) 
    }

    private _onMenuClose(action:string) {
      this.setState({ anchorEl: null});
      if(action === 'delete'){
        this.setState({confirmDialogState : !this.state.confirmDialogState});
      }else if(action === 'edit'){
        //this.setState({confirmDialogState : !this.state.confirmDialogState});
      } 
      //console.log(action + " on: " + this.state.menuDUID);
    }

    componentDidMount() {
        // get devices
        this.getDevices();
    }

    getDevices(){
        api.backendCall({action:actions.ACTION_GET_DEVICES})
        .then((response)=>{
            //console.dir(response);
            this.setState({
              devices:response.data.result,
              selectedSate:Array(response.data.result.lenght).fill(false)
            });            
        })
        .catch((error) => {api.errorAlert(error);}) 
    }
    
    getSensors(dev: DeviceType){      
        dev.sensors.sort((a, b) => (a.type > b.type) ? 1 : -1);
        var indexCounter = 0;
        
        return (
          <>
          {
          dev.sensors.map(elem => (          
            <ToggleButton style={{marginLeft: '10px'}} key={"sens."+elem.device_id+"."+elem.type + "." +(indexCounter++)} 
              value="check" 
              selected={elem.selected}
              disabled={!elem.channel && (elem.type==='Video') }
              onChange={() => {
                elem.selected = !elem.selected;
                this.setState({devices:[...this.state.devices]});
              }}
            >
              <SensorIcon sensType={elem.type}/>
            </ToggleButton>
          ))
          }
          </>
        );
    }

    render(){
        {
        // find the video clients
        const arrVideoViewers : Sensor[]=[];
        this.state.devices.forEach(elem => (
          elem.sensors.forEach(sens => {
              if(sens.selected && sens.type===DEVICE_TYPE.VIDEO && sens.channel){
                arrVideoViewers.push(sens);
              }
              //console.log(sens);
          })
        ));
        const { anchorEl } = this.state;
        return  (
            
          <BaseComponent displayTimeDateRange={false}>
            <div className="text-center">            
            <h1>Devices</h1>

            <TableContainer component={Paper}>
                <Table  aria-label="simple table">
                <TableHead>
                    <TableRow>
                    <TableCell></TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell align="right">Sensors</TableCell>
                    <TableCell align="right">Address</TableCell>
                    <TableCell align="center">ID</TableCell>
                    <TableCell align="center">Registered at</TableCell>
                    <TableCell align="right">Groups</TableCell>
                    <TableCell align="right"></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {this.state.devices.map((row) => (
                    <TableRow key={row.duid}>
                        <TableCell>
                        <IconButton>
                            <ExpandMore/>
                        </IconButton>
                        </TableCell>
                        <TableCell component="th" scope="row">
                        <Link to={'/device?id='+row.duid}>{row.name}</Link>
                        </TableCell>
                        <TableCell align="right">{this.getSensors(row)}</TableCell>
                        <TableCell align="right">{row.address}</TableCell>
                        <TableCell align="center">{row.duid}</TableCell>
                        <TableCell align="center">{DateTimeUtils.formatDate(row.createtime)}</TableCell>
                        <TableCell align="right">{row.group_name}</TableCell>
                        <TableCell align="right">
                            <IconButton onClick={e => this._onOpenMenu(e,row.duid)}>
                                <MoreVertIcon/>
                            </IconButton>
                        </TableCell>
                    </TableRow>
                    ))}
                </TableBody>
                </Table>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  keepMounted
                  onClose={()=>this._onMenuClose("")}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <MenuItem style={{ color: 'red' }} onClick={()=>this._onMenuClose("delete")}>Remove</MenuItem>
                  <MenuItem onClick={()=>this._onMenuClose("edit")}>Edit</MenuItem>
                </Menu>                
            </TableContainer>
            <ConfirmDialog title="Confirm Action" open={this.state.confirmDialogState} 
                           message={" Remove device '" +this.state.menuDUID + "' and all its sensors data?"}
                           onConfirm={this.removeDevice}
                           onCancel ={()=>{this.setState({confirmDialogState : !this.state.confirmDialogState})}}/>
            <div className="video-container">
            <>
                {arrVideoViewers.map((elem) => (
                <div className="container__grid" style={{width:'640px', height:'450px'}}>
                  <VideoViewer param={elem} key={"video."+elem.device_id}/>
                </div>
                ))}
            </>
            </div>
            </div>
            </BaseComponent>
          )
        }
    }
}