import { DATETIME_RANGE, MILLIS_INDAY_LESSONE} from './constants';

export class DateTimeUtils{
    static formatDateTime(datetimestr:string){
        return DateTimeUtils.formatDateObj(new Date(parseInt(datetimestr)));
    }

    static formatDate (datestr:string) {
      return DateTimeUtils.formatDateObj(new Date(datestr));

    }

    static formatDateObj(d:Date){
      var month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();
      var hours=''+d.getHours();
      var mins=''+d.getMinutes();
      var secs=''+d.getSeconds();  
      if (month.length < 2) 
          month = '0' + month;
      if (day.length < 2) 
          day = '0' + day;
      if(hours.length<2)
        hours = '0'+hours;
     if (mins.length < 2) 
          mins = '0' + mins;
      if (secs.length < 2) 
          secs = '0' + secs;
        
      return [year, month, day].join('-') +"  " + [hours,mins,secs].join(':');
    }
    
    static getRangeStr(range : any){
        var dateTimeRangeStr= ('label' in range)?range['label']:range?.startDate?.toLocaleDateString() + " - " + range?.endDate?.toLocaleDateString();
        return dateTimeRangeStr;
    }
    
    static setDateTimeRange(range : any){
        // adjust the endDate to reflect the end of day
        range.endDate = new Date(range.endDate.getTime() + MILLIS_INDAY_LESSONE);
        // save to local store
        range['label']=DateTimeUtils.getRangeStr(range);
        localStorage.setItem(DATETIME_RANGE, JSON.stringify(range));
        //console.log("change date: ", range);
        return range;
    }

    static getDateTimeRange(){
        let rangeStr = localStorage.getItem(DATETIME_RANGE);
        let range: any;
        if(!rangeStr){
            // get default date: today
            let crtDate : Date = new Date(); 
            range={
                startDate:crtDate,
                endDate:new Date(crtDate.getTime()+MILLIS_INDAY_LESSONE)
            }
            // save to local store
            range['label']=DateTimeUtils.getRangeStr(range);
            localStorage.setItem(DATETIME_RANGE, JSON.stringify(range));
        }else{
            range = JSON.parse(rangeStr);
            range.startDate = new Date(range.startDate?range.startDate:"");
            range.endDate = new Date(range.endDate?range.endDate:"");
        }
        return range;
    }
}
  