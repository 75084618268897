import React from 'react'
// @ts-ignore
import {Sensor, DeviceType,GPSSessionData} from '../../../Data/Devices'
import {GPSRenderer} from '../../../Utils/GPSRenderer';

interface GPSViewerProps{
    defaultSensorID?:string,
    data:any[],
    device?:DeviceType,
    sensor?:Sensor
}

class GPSViewer extends React.Component<GPSViewerProps>{

    getSessions(){
        let gpsdata = this.props.data;
        if(!gpsdata){
            return [];
        }
        let prevPoint:Number|undefined = undefined;
        let sessions:GPSSessionData[]=[];
        let crtSession:GPSSessionData;
        gpsdata.forEach(point=>{
            if(prevPoint !== point.vals[5]){
                // start a new session
                crtSession={
                    id:point.vals[5],
                    meters: -1,
                    startime: "",
                    duratsec: -1,
                    did: "",
                    points:[]
                };
                sessions.push(crtSession);
                prevPoint = point.vals[5];                
            }
            crtSession.points.push(point);
        })
        return sessions;
    }

    render(){
        let sessions:any = this.getSessions();
        return(
          <div className="container__grid">
            <div className="item__title">
            </div> 
                <GPSRenderer sessions={sessions} manageState={true} showControls={true}/>
          </div>
        )
    }
}


export default GPSViewer;