import React from 'react'
import {VideoViewer} from '../../DashboardContainer/VideoViewer'
import {Sensor, DeviceType} from '../../../Data/Devices'


export interface VideoViewerState{   
    device:DeviceType,
    sensor:Sensor
}

export class VideoViewerContainer extends React.Component<VideoViewerState>{

    render(){
        return(
        <div className="container__grid">
             <div className="item__title"></div>
             <VideoViewer param={this.props.sensor} key={"video."+this.props.device.duid}/>
        </div> 
        )
    }
}
