import * as React from 'react';
import { Route } from 'react-router-dom';
import { BrowserRouter, Switch } from 'react-router-dom';
/** Container components */
import CrazyComponent from '../Containers/CrazyContainer/CrazyComponent';
import DeviceListViewComponent from '../Containers/ViewContainer/DeviceListViewComponent';
import LoginContainer from '../Containers/LoginContainer';

import SignUpContainer from '../Containers/SignUpContainer';
import ConfirmEmailContainer from '../Containers/ConfirmEmailContainer';
import ForgotPasswordContainer from '../Containers/ForgotPasswordContainer';
import PasswordResetContainer from '../Containers/PasswordResetContainer';
import { createBrowserHistory } from "history";
/** private route component */
import PrivateRoute from './PrivateRoute';
import DeviceViewComponent from '../Containers/ViewContainer/DeviceViewComponent';
//import { Provider } from "react-redux";
//import configureStore from "../Data/configureStore";

//const store = configureStore();
createBrowserHistory();
class AppRouter extends React.Component {
  render() {
    return (      
      <BrowserRouter>
        <Switch>
          <Route exact={true} path="/" component={LoginContainer} />
          <Route exact={true} path="/login" component={LoginContainer} />
          <Route exact={true} path="/signup" component={SignUpContainer} />
          <Route exact={true} path="/verify-code" component={ConfirmEmailContainer} />
          <Route exact={true} path="/reset-password" component={PasswordResetContainer} />
          <Route exact={true} path="/forgot-password" component={ForgotPasswordContainer} />
          <PrivateRoute exact={true} path="/dashboard" component={DeviceListViewComponent} />
          <PrivateRoute exact={true} path="/gpus" component={CrazyComponent} />
          <PrivateRoute exact={true} path="/device" component={DeviceViewComponent} />
          </Switch>
      </BrowserRouter>
      
    );
  }
}

export default AppRouter;
