import React, { Component } from 'react'
import Dialog from "@material-ui/core/Dialog"
import Button from "@material-ui/core/Button";
import { DateRangePicker, DateRange } from "materialui-daterange-picker";
import { RouteComponentProps} from 'react-router';
import {DateTimeUtils} from '../../Utils/DateTimeUtils';

interface DateTimePickerState{
    isDialogOpen: boolean,
    dateRangeObject:any
}

interface DateRangePickerProps extends RouteComponentProps{
    onChange: (range: DateRange) => void;
}


export default class DateTimeRangePicker extends Component<DateRangePickerProps, DateTimePickerState>{

    constructor(props:DateRangePickerProps) {
        super(props);
        let range = DateTimeUtils.getDateTimeRange();
        this.state={
            isDialogOpen:false,
            dateRangeObject:range
        }
    }

    setDateRange(range:any){
        if(!range.startDate || !range.endDate){
          return;
        }
        range = DateTimeUtils.setDateTimeRange(range);

        // set state
        this.setState({
          isDialogOpen: false,
          dateRangeObject:range
        })        
        this.props.onChange(range);
    }

    renderDateTimeRange(){    
        const toggle = () => this.setState({isDialogOpen:!this.state.isDialogOpen});
        
        return(
        <div>
            <Dialog open={this.state.isDialogOpen} 
                    fullWidth
                    maxWidth="md"
                    onBackdropClick={toggle}
                    disableEnforceFocus
                    style={{ pointerEvents: 'none' }}
                    PaperProps={{ style: { pointerEvents: 'auto' } }}
            >
            <DateRangePicker
                open={this.state.isDialogOpen}
                toggle={toggle}
                maxDate={new Date()}
                initialDateRange={this.state.dateRangeObject}
                onChange={(range) => this.setDateRange(range)}
                ></DateRangePicker>
            </Dialog>
        </div>
        )
    }

    render(){    
        //const toggle = () => this.setState({isDialogOpen:!this.state.isDialogOpen});
        return(
        <div>
             {this.renderDateTimeRange()}
            <Button variant="outlined" onClick={() => { this.setState({isDialogOpen:!this.state.isDialogOpen}) }}>{this.state.dateRangeObject['label']}</Button>
        </div>
        )
    }
}