import React, { Component} from 'react'
import BaseComponent from '../BaseComponentContainer/BaseComponent'
import { RouteComponentProps} from 'react-router';
import api  from '../../Utils/Helpers';
import { actions} from '../../Utils/constants';
import {Sensor, DeviceType} from '../../Data/Devices'

import GridLayoutComponent from './GridLayoutComponent'
import {DateTimeUtils} from '../../Utils/DateTimeUtils';
import {DateRange } from "materialui-daterange-picker";
import SessionsViewComponent from "./SessionsViewComponent";
 
interface DeviceViewState {
    device?: DeviceType,    
    sensorData?:any,
    error  : string,
    layout : any[],
    metaData: any,
}
interface PanelInfo{
    sensors:Sensor[], // sensor of a certain type
    type: string
}

export default  class DeviceView extends Component<RouteComponentProps, DeviceViewState>{
    
    constructor(props:RouteComponentProps) {
        super(props)
        this.state={
            error:'',
            layout:[],
            sensorData:undefined,
            metaData:null
        }
        this.onLayoutChange = this.onLayoutChange.bind(this);
    }

    onLayoutChange(layout:any[]) {
        this.setState({ layout: layout });
    }    

    componentDidMount() {
        this.loadDeviceData();
    }

    onDateChange(){
        //console.log("reload data on date change");
        this.loadDeviceData();
    }

    loadDeviceData(){
        //const params = this.props.match.params as any
        const queryParams = new URLSearchParams(window.location.search)
        const deviceid = queryParams.get('id');
        console.log(deviceid);
        let range : DateRange = DateTimeUtils.getDateTimeRange();
        let dtRangeStr=range.startDate?.getTime()+"-"+range.endDate?.getTime();
        api.backendCall({action:actions.ACTION_GET_SENSORDATA, params:{'did':deviceid,'timerange':dtRangeStr}})
        .then((response)=>{
            //console.log('Device data:',response.data.result.devices[0]);
            this.setState({
              device:response.data.result.devices[0],
              sensorData:response.data.result.sensorData,
              metaData:response.data.result.metaData,
              error:''
            });
        })
        .catch((error) => {
            console.log("error loading device: ");            
            //console.dir(error.response.data.error);
            this.setState({
                error:api.getError(error,'Unable to get device')
              });  
            api.errorAlert(error);
            this.setState({
                sensorData:undefined,
                error:error.message
              });
          }) 
    }

    getPanelInfoList(sensors:Sensor[]){
        if(sensors==null || sensors.length==0 || this.state.sensorData === undefined || this.state.device === undefined){   
            console.log("no sensors data.1");         
            return [];
        }
        // by default consolidate all sensors of the same type into one panel
        //var lenSensArray = sensors.length;
        
        //console.log(this.state.deviceData);
        // scan all the sensors and add them to the list according to the requested order
        // todo add requested order 
        var out = new Map<string,PanelInfo>();
        var sensData=this.state.sensorData[this.state.device.duid];        
        for (const elem of sensors) {
            if(!out.has(elem.type)){
                out.set(elem.type, {sensors:[],type:elem.type})
            }
            var panel: PanelInfo = out.get(elem.type)!;
            panel.sensors.push(elem);  
            var arrType =sensData!=undefined?sensData[elem.type]:undefined;
            
            //var idInt = parseInt(elem.id, 10);
            if(arrType !== undefined ){
                elem.data = arrType[elem.id];
            }
            
        }
        return Object.fromEntries(out);
    }

    renderDevice(){
        if(this.state.device === undefined || this.state.sensorData === undefined){
            return ('... loading');
        }else if(this.state.error != ''){
            return (this.state.error)
        }
        // compute the panels to draw
        // create sensors panel layout (group by type) 
        var mapPanels = this.getPanelInfoList(this.state.device.sensors);        
        return (
            <div>
            {this.state.device.duid}
            <GridLayoutComponent onLayoutChange={this.onLayoutChange} device={this.state.device} deviceData={this.state.sensorData} mapPanels={mapPanels}/>
            </div>
        )
    }

    render(){    
        return  (
            <BaseComponent onDateChange={this.onDateChange.bind(this)}>
                {this.renderDevice()}
                <SessionsViewComponent device={this.state.device===undefined?[]:[this.state.device]} sensorData={this.state.sensorData} metaData={this.state.metaData}/>
            </BaseComponent>
            )
       }
}
