/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_pIOX9tuW6",
    "aws_user_pools_web_client_id": "3tfap8sp22jc58el6gvvi66p3v",
    "oauth": {},
    "aws_identity_pool_id":"us-east-1:4512dae4-c608-41e8-98f4-ea12faee5740",
    "react_app_mqtt_id":"a1n30eu383eipn-ats"
};


export default awsmobile;
