import awsconfig from '../aws-exports';
import AWSIoTData from 'aws-iot-device-sdk';
import { API_AUTH_TOKEN, MQTT_CLIENT_ID} from '../Utils/constants';
import {ENV} from '../env';

//import { env } from 'process';
var AWS = require('aws-sdk');

//========================================================================
const MQTT_GLOBAL_TOPIC_ID    = 'global';
const MQTT_TOPIC_PREFIX_ALERT = 'alert';

export function getGlobalMQTTtopic(){
    return ENV+":"+MQTT_TOPIC_PREFIX_ALERT+":"+MQTT_GLOBAL_TOPIC_ID;
}


export abstract class AlertListener{
  abstract notify(topic:string, payload:string):void;
}

export class MQTTCommunicator{
    newMqttClient : any;
    isInitialized : boolean;
    globalTopics: string[];
    listeners: Map<string,AlertListener>;

    constructor() {
      this.isInitialized = false;
      this.newMqttClient = null;
      this.globalTopics = [getGlobalMQTTtopic()];
      this.listeners = new Map() as Map<string,AlertListener>;
    }

    registerListener(key:string, listener: AlertListener){
      this.listeners.set(key, listener);
    }

    unregisterListener(key:string){
      this.listeners.delete(key);
    }

    /*
    removeTopic(topic:string){
      this.globalTopics.filter(function(ele){
        return ele !== topic;
      });
      if(this.newMqttClient && this.isInitialized){
        this.newMqttClient.subscribe(this.globalTopics);
      }    
    }

    addTopic(topic:string){
      this.globalTopics.push(topic);
      if(this.newMqttClient && this.isInitialized){
        this.newMqttClient.subscribe(this.globalTopics);
      }
    }

    addTopics(topics:string[]){
      this.globalTopics.push(...topics);
      if(this.newMqttClient && this.isInitialized){
        this.newMqttClient.subscribe(this.globalTopics);
      }
    }
*/
    setTopics(topics:string[]){
      this.globalTopics=[getGlobalMQTTtopic()];
      topics.forEach(element => {
        this.globalTopics.push(ENV+":"+MQTT_TOPIC_PREFIX_ALERT+":"+element)
      });
      //this.globalTopics.push(...topics);
      if(this.newMqttClient && this.isInitialized){
        this.newMqttClient.subscribe(this.globalTopics);
      }
    }

    connectToAwsIot(callbackListener:(topic:string, payload:string)=>void) {
      if (this.newMqttClient){
        return;
      }
      
      var clientId = localStorage.getItem(MQTT_CLIENT_ID);
      if(!clientId){
        clientId = 'mqtt-explorer-' + (Math.floor((Math.random() * 100000) + 1));
        localStorage.setItem(MQTT_CLIENT_ID, clientId);
      }

      let hostStr = awsconfig.react_app_mqtt_id+'.iot.'+awsconfig.aws_project_region+'.amazonaws.com';
      this.newMqttClient = new AWSIoTData.device({
          region: awsconfig.aws_project_region,
          host:hostStr,//AWSConfiguration.host,  
          clientId: clientId,
          protocol: 'wss',
          maximumReconnectTimeMs: 8000,
          debug: true,
          accessKeyId:  '',
          secretKey:    '',
          sessionToken: ''
        });
        let that = this;
        // On connect, update status
        this.newMqttClient.on('connect', function() {
          //arn:aws:iot:us-east-1:756819255899:topic/
          that.newMqttClient.subscribe(that.globalTopics);
          that.isInitialized = true;
          console.log('Publisher connected to AWS IoT.');
        });
        this.newMqttClient.on('error', function(){
          console.log('error connecting to AWS IoT.' );
        });
        this.newMqttClient.on('reconnect', function(){
          console.log('reconnect to AWS IoT.');
        });
        this.newMqttClient.on('message', function(topic:string, payload:string){
          callbackListener(topic, payload);
          
          that.listeners.forEach(function(value, key) {
            value.notify(topic, payload);
          })
          //console.log('message from AWS IoT.' + payload);
        });
        this.newMqttClient.on('close', function(){
          console.log('close AWS IoT.' );
        });
        this.newMqttClient.on('offline', function(){
          console.log('offline AWS IoT.' );
        });    

        console.log("================== connect setup for newMqttClient");
        //console.log("idpool: "+awsconfig.aws_identity_pool_id);
        //console.log("hostStr: " + hostStr);
        // get credentials and, from them, extract key, secret key, and session token
        // Amplify's auth functionality makes this easy for us...
        AWS.config.region = awsconfig.aws_cognito_region;
        //console.dir(awsmobile);
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: awsconfig.aws_identity_pool_id,
            Logins: {
              ['cognito-idp.'+awsconfig.aws_cognito_region+'.amazonaws.com/'+awsconfig.aws_user_pools_id]: localStorage.getItem(API_AUTH_TOKEN)
            }
        });

        AWS.config.credentials.get(function(){
          console.log("================== setup creds for newMqttClient : "+AWS.config.credentials.identityId);

          that.newMqttClient.updateWebSocketCredentials(
            AWS.config.credentials.accessKeyId, 
            AWS.config.credentials.secretAccessKey, 
            AWS.config.credentials.sessionToken,
            AWS.config.credentials.expireTime); 
      })
    }

    disconnectAWSIoT(){
      if (this.newMqttClient){
        this.newMqttClient.end(false);
        this.isInitialized = false;

        localStorage.removeItem(MQTT_CLIENT_ID);
        console.log("================== disconnect newMqttClient");
      }
    }
}

const mqttCommunicator = new MQTTCommunicator();

export default mqttCommunicator;