/** Auth user token key */
export const AUTH_USER_TOKEN_KEY = 'ReactAmplify.TokenKey';
export const API_AUTH_TOKEN      = 'Authentication.Token';
export const MQTT_CLIENT_ID      = 'MQTT.ClientID';
export const USERNAME            = 'username';
export const MENU_COLLAPSED_FLAG = 'Menu.colapse';
export const DATETIME_RANGE      = 'datetime.range';
// this key is secured to run only on axsept.com
export const GOOGLE_API_KEY      = 'AIzaSyDCt2te4pw4pbpHZ8c2aQSf_6Zubd8_XK0';


export const MILLIS_INDAY_LESSONE = 24*60*60000-1;

export const actions = Object.freeze({
    ACTION_CREATE_ACCOUNT :   'account.create',
    ACTION_REGISTER_DEVICE:   'device.register',
    ACTION_UNREGISTER_DEVICE: 'device.unregister',
    ACTION_GET_DEVICES:       'device.list',    
    ACTION_GET_DEVICE:        'device.get',    
    ACTION_REGISTER_SENSOR:   'sensor.register',
    ACTION_REGISTER_VIDEO_CHANNEL: 'sensor.registerVideoChannel',
    ACTION_DELETE_DEVICE:     'device.delete',
    ACTION_GET_SENSORDATA:    'sensor.getData',
    ACTION_PUT_SENSORDATA:    'sensor.putData',
    ACTION_DELETE_ALL_SENSORDATA: 'sensor.deleteAll'
});


export const DEVICE_TYPE = Object.freeze({
    VIDEO:"Video"
});
