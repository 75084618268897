import * as React from 'react';
import * as ReactDOM from 'react-dom';
import Amplify from 'aws-amplify';
import { Provider } from 'react-redux'
//import configureStore from "./Data/configureStore";


/** Presentational */
import App from './Components/App';
import { GlobalStyles } from './global';
import firebase  from "firebase/app";
import 'firebase/analytics'

/** Amplify config */
import awsconfig from './aws-exports';

/** Service worker */
import * as serviceWorker from './serviceWorker';
import store from './store'

var firebaseConfig = {
  apiKey: "AIzaSyDCt2te4pw4pbpHZ8c2aQSf_6Zubd8_XK0",
  authDomain: "dev-axsept.firebaseapp.com",
  projectId: "dev-axsept",
  storageBucket: "dev-axsept.appspot.com",
  messagingSenderId: "793151078699",
  appId: "1:793151078699:web:2e8dca94282b49a009856e",
  measurementId: "G-WXKS2JTEYZ"
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
// Initialize Analytics and get a reference to the service
firebase.analytics(app);

//const store = configureStore();

/** Configure amplify */
Amplify.configure(awsconfig);


ReactDOM.render(  
  <Provider store={store}>
    <GlobalStyles />
    <App />
  </Provider> 
  ,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
