import React from 'react'
import styled from "styled-components";
import { ResponsiveContainer, LineChart as ChartLine, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend} from "recharts";
import {Sensor, DeviceType} from '../../../Data/Devices'

const Container = styled(ResponsiveContainer)`
  .recharts-line {
    filter: url(#dropshadow);
  }
`;

const center_component = {
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center'
};

const ActiveCricle = styled.circle`
  filter: drop-shadow(rgb(68, 68, 221) 30px 10px 4px);
`;

export interface SingleValueViewerProps{
    unit:string,
    name:string,
    data:any[],
    device?:DeviceType,
    sensor?:Sensor
}

export class SingleValueViewer extends React.Component<SingleValueViewerProps>{

    renderDot(){
        return (
            <>
              <ActiveCricle r="7.5" stroke-width="3" />
              <ActiveCricle r="4" stroke-width="1" fill="#fff" />
            </>
          );
    }

    render(){
        //const displayStr = this.props.name + " : " + this.props.unit;
        // prepare data
        var values = [];
        const nameVal=this.props.name;
        if(this.props.data === undefined ){
          return (
            <div style={center_component}>
            No {nameVal} data
            </div>
            );
        }
        for(const val of this.props.data){
            values.push({[nameVal]:val.vals[0], "label":val.time});
        }
        //console.dir('sensvalues:',this.props.data);
        //values.push({"x":20,"y":10, "label":"foo"});
        //values.push({"x":30,"y":20, "label":"boo"});
        //activeDot={this.renderDot} 
        return( 
          <div className="container__grid">
            <div className="item__title">
            </div> 

            <Container width="100%" height="100%">
            <ChartLine
              data={values}
              margin={{
                top: 30,
                right: 30,
                left: 10,
                bottom: 10
              }}
            >
              <filter id="dropshadow" height="400%">
                <feDropShadow dx="0" dy="15" stdDeviation="5" floodColor="parent" floodOpacity="0.25" />
              </filter>
              <CartesianGrid strokeDasharray="0 0" stroke="#efefef" vertical={false} />
              <XAxis dataKey="label" stroke="#e7e7e7" tickLine={false} tick={{ fontSize: 10, fill: "#576b7c" }} />
              <YAxis axisLine={false} tickLine={false} tick={{ fontSize: 10 }} />
              <Tooltip />
              <Legend />
              <Line dot={false} type="monotone" dataKey={this.props.name} stroke="#8884d8" strokeWidth={3} />
            </ChartLine>
            
            </Container>
          </div>
        )
    }
}