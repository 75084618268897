import React, { Component } from 'react'
import { RouteComponentProps} from 'react-router';

import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess'; 
import { Layout } from 'antd';
import { colors } from '../../Themes/Colors';
import { AUTH_USER_TOKEN_KEY} from '../../Utils/constants';
import { crazy_actions, filter_types} from './constants';
import { Auth } from 'aws-amplify';
import api  from '../../Utils/Helpers';

import Switch from '@material-ui/core/Switch';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { integer } from 'aws-sdk/clients/frauddetector';
import { float } from 'aws-sdk/clients/lightsail';
import { Checkbox } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { API_AUTH_TOKEN } from '../../Utils/constants';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import mqttCommunicator, {getGlobalMQTTtopic} from '../../Utils/MQTTcommunicator';
import {SiteConfig} from './SiteConfig'
import {VendorsHeader} from './VendorsHeader' 
//import clone from 'lodash';


interface Model{
  modelName:string,
  title:string,
  chipset:string,
  ramGB:integer,
  chipProducer:string
}
interface Item{
  model:string,
  title:string,
  itemId:string,
  configID:integer,
  link:string,
  lastupdate:string,
  lastprice:float,
  stockStatus:integer
}
interface Result{
  id:integer,
  timeStart:string,
  timeStop:string,
  itemId:string,
  price:float,
  configId:integer
}

interface AlertCondition{
  maxPrice:integer,
  mainValue:string,
  mainType:string,
  enabled:boolean
}

interface AllCrazyData{
    config:SiteConfig[],
    models:Model[],
    items:Item[],
    alertsConditions:AlertCondition[]
}

interface ItemResult{
  item:Item,
  model:Model
}

interface ModelResult{
  model:Model,
  totalAvail:integer,
  itemResult:ItemResult[]
}

interface ChipsetData{  
  chipSetName:string,
  chipProducer:string,
  sortKey:string,
  displayFlag:boolean,
  minPrice:float,
  maxPrice:float,
  notificationEnabled:boolean,
  idx:integer,
  modelResults:Map<string,ModelResult>
}

interface DisplayFlags{
  showAllItems:boolean,
  showAllTypes:boolean
}

interface MyState {
  timestamp:Date,
  timestampRefresh:Date,
  timestampTokenRefresh:Date,
  username: string,
  data: AllCrazyData,
  chipsetArray:ChipsetData[],
  configMap:Map<integer,SiteConfig>,
  displayFlags:DisplayFlags,
  intervalId:integer
}

export default  class CrazyComponent extends Component<RouteComponentProps, MyState>{

    //communicator : MQTTCommunicator;
    globalTopic: string;

    constructor(props:RouteComponentProps) {
       super(props);
       this.state = {
        timestamp:new Date(0), 
        timestampRefresh:new Date(0),
        timestampTokenRefresh:new Date(),
        username:'<unknown>',
        data : {} as AllCrazyData,
        chipsetArray:[],
        configMap: new Map(),
        displayFlags:{} as DisplayFlags,
        intervalId:0
       }
       //this.communicator = new MQTTCommunicator();
       this.globalTopic = getGlobalMQTTtopic();
       this.refreshData = this.refreshData.bind(this);
       this.logout = this.logout.bind(this)
       this.handleNotificationChange = this.handleNotificationChange.bind(this)       
    }

    computeChipsetData(rawData:AllCrazyData){      
      var mapChipset = new Map();
      var mapItemsByModel  = new Map();
      //console.dir(rawData);      
      rawData.items.forEach(item =>{
          let arr = new Array();
          if(!mapItemsByModel.has(item.model)){
            mapItemsByModel.set(item.model,arr);
          }else{
            arr = mapItemsByModel.get(item.model);
          }
          let itRes={}as ItemResult;
          itRes.item = item;
          arr.push(itRes);
      })
      // 
      var mapNotifications = new Map();
      mapNotifications.set(filter_types.CHIPSET,new Map());

      rawData.alertsConditions.forEach(item =>{
        mapNotifications.get(item.mainType).set(item.mainValue, item);
      })
      // set notification topic
      let arrTopics:string[]=Array.from(mapNotifications.get(filter_types.CHIPSET).keys());
      mqttCommunicator.setTopics(arrTopics);
      
      //console.dir(mapItemsByModel);
      rawData.models.forEach(item => {
        let csdata:ChipsetData = {} as ChipsetData;
        if(!mapChipset.has(item.chipset)){
          csdata.chipSetName = item.chipset;
          csdata.chipProducer= item.chipProducer;
          csdata.modelResults = new Map();
          csdata.displayFlag = false;
          csdata.notificationEnabled = mapNotifications.get(filter_types.CHIPSET).has(csdata.chipSetName);
          mapChipset.set(item.chipset,csdata);        
        }else{
          csdata = mapChipset.get(item.chipset)
        }
        if(mapItemsByModel.has(item.modelName)){
          let tmp = {} as ModelResult;
          tmp.itemResult=mapItemsByModel.get(item.modelName);
          tmp.model=item;          
          csdata.modelResults.set(item.modelName,tmp);  
        }
      });

      // iterate through the mapChipset and create an array
  
      let values = Array.from( mapChipset.values() );
      
      values.forEach(elem =>{
        if(!elem.chipSetName){
          elem.chipSetName="?";
        }
        if(!elem.chipProducer){
          elem.chipProducer="?";
        }
        elem.sortKey  = elem.chipProducer + elem.chipSetName.toUpperCase();
        elem.minPrice = 1000000000;
        elem.maxPrice = -1;
        for (let [key, value] of elem.modelResults) {
          value.totalAvail = this.getAvailableCountSummary(value.itemResult, elem);
        }
      })
      values.sort((a,b)=>(a.sortKey>b.sortKey)?-1:1)
      let i=0;
      values.forEach(elem=>{elem.idx=i++})
      var flagShowAll:boolean = rawData.alertsConditions.length===0;
      
      return {values, flagShowAll};
    }

    componentDidMount() {
      let token = localStorage.getItem(AUTH_USER_TOKEN_KEY);
      const { history } = this.props;
      if(token == null){
        history.push('/login');
      }        
      // get devices
      var ts  = new Date();
      var tse = ts.toISOString().replace(/T/, ' ').replace(/\..+/, '');
      var tsb = new Date(ts.getTime() - 48*3600000).toISOString().replace(/T/, ' ').replace(/\..+/, '');

      //console.log("Authorization:"+localStorage.getItem(API_AUTH_TOKEN));
      let varIntId = 1;//setInterval(this.refreshData, 2500);

      api.backendCall({action:crazy_actions.CRAZY_ACTION_GET_DATA, params:{fromTime:tsb,toTime:tse}})
      .then((response)=>{
          let configMap = new Map();
          //console.dir(response.data.result.config);
          response.data.result.config.map((elemCT:SiteConfig)=>{
              return configMap.set(elemCT.id, elemCT);
          });
          var varx = this.computeChipsetData(response.data.result);
          this.setState({
            timestamp:new Date(),// this data should come from the backend and should be last updated config
            timestampRefresh:new Date(),
            username:response.data.username,
            data:response.data.result,
            chipsetArray:varx.values,
            configMap:configMap,
            displayFlags:{showAllItems:false, showAllTypes:varx.flagShowAll},
            intervalId:varIntId
          });
          //console.dir(response);
      })
      .catch((error) => {api.errorAlert(error);}) 
      //this.state.intervalId = setInterval(this.refreshData, 2000);
      mqttCommunicator.connectToAwsIot((message:string, payload:string)=>{
        //console.log("Crazy notification message: " + payload);
        try{
          this.processItemUpdate(message, payload);          
        }catch(error) {
          console.log(error)
        }
      });
    }

    processItemUpdate(topic:string, payload: string){
        if(topic === this.globalTopic){
          return;
        }
        console.log("process message: " + payload);
        var obj = JSON.parse(payload);
        var newItems = [...this.state.data.items];

        var itemIndex = -1;
        for(var i=0;i<newItems.length;i++) {
          if(obj.itemId===newItems[i].itemId){
            newItems[i].stockStatus = obj.stockStatus;
            newItems[i].lastprice = obj.lastprice;
            itemIndex = i;
            break;
          }
        };

        if(itemIndex===-1){
          this.refreshData();
          return;
        }
        var newStateData={
          config:this.state.data.config,
          models:this.state.data.models,
          items:newItems,
          alertsConditions:this.state.data.alertsConditions
        };
        
        var configMap = this.state.configMap
        var varx = this.computeChipsetData(newStateData);            
        this.setState({
          timestampRefresh:new Date(),
          data:newStateData,
          chipsetArray:varx.values,
          configMap:configMap,
       });

        console.log("Update state for item: " + obj.itemId + " state: " + obj.stockStatus);
        // at this point we have the item - just change status and price
        //this.setState(clonedState);
    }

    componentWillUnmount(){
      clearInterval(this.state.intervalId);
      mqttCommunicator.disconnectAWSIoT();
    }

    async refreshData() {
      try {
        // check if we need to refresh the token
        let crtdate=new Date();

        if(crtdate.getTime() >  600000 + this.state.timestampTokenRefresh.getTime()){
            // get the current session
            console.log("refresh the token");
            try {
              const cognitoUser = await Auth.currentAuthenticatedUser();
              const currentSession = await Auth.currentSession();
              cognitoUser.refreshSession(currentSession.getRefreshToken(), (err:any, session:any) => {
                  const { idToken } = session;
                     localStorage.setItem(API_AUTH_TOKEN,idToken.jwtToken);
                     this.performRefresh();
                     //this.setState({timestampTokenRefresh : new Date()});
            });
            } catch (e) {
              const { history } = this.props;
              console.log('Unable to refresh Token', e);
              localStorage.removeItem(AUTH_USER_TOKEN_KEY);
              history.push('/login');
            }
        }else{
          this.performRefresh();
        }

      } catch (e) {
          console.log(e);
          window.location.reload(); 
          this.setState({
            timestampRefresh:new Date(),
          })
      }
    }

    async setAlertCondition(alertConds: AlertCondition[]){
      api.backendCall({action:crazy_actions.CRAZY_ACTION_SET_ALERT, params:{alertConditions:alertConds}})
      .then((response)=>{
        
      })
    }

    performRefresh(){
      api.backendCall({action:crazy_actions.CRAZY_ACTION_REFRESH_DATA, params:{lastRefresh:this.state.timestamp}})
      .then((response)=>{
        let configMap = new Map();
        response.data.result.config.map((elemCT:SiteConfig)=>{
          return configMap.set(elemCT.id, elemCT);
        });

        if(!response.data.result.items){
          console.log("no data change");
          /*
          let newData:AllCrazyData =this.state.data;
          newData.config = response.data.result.config;
          this.setState({
            timestampRefresh:new Date(),
            configMap:configMap,
            data:newData
          });
          */
        }else{  
          console.log("datachange!");
          var varx = this.computeChipsetData(response.data.result);            
          this.setState({
            timestamp:new Date(),// this data should come from the backend and should be last updated config
            timestampRefresh:new Date(),
            data:response.data.result,
            chipsetArray:varx.values,
            configMap:configMap,
         });
        }
      });
    }


    getAvailableCountSummary(itemResults: ItemResult[], cset:ChipsetData){
      if(!itemResults || itemResults.length ===0){
        return 0;
      }
      let totAvail=0;
      itemResults.map((elem) => {
        if(elem.item.stockStatus===1){
          totAvail+=1;
        }
        if(elem.item.lastprice && elem.item.lastprice > 0){
          cset.minPrice = elem.item.lastprice < cset.minPrice ? elem.item.lastprice:cset.minPrice;
        }
        cset.maxPrice   = elem.item.lastprice > cset.maxPrice ? elem.item.lastprice:cset.maxPrice;
        return 0;
      });
      return totAvail;
    }

    renderResults(item:ItemResult){
      let siteConf = this.state.configMap.get(item.item.configID);
      if(!siteConf){
        return("")
      }
      let siteName = siteConf.siteName;

      return (
        <p style={{marginTop:'10px'}}>
        <a href={item.item.link}> {siteName} </a>
        </p>
        );
    }

    getTotalAvailable(modelResults:Map<string,ModelResult>){
      let items = Array.from( modelResults.values() );
      let total=0;
      items.forEach(elem=>{
        if(elem.itemResult){
          elem.itemResult.forEach(elVal=>{
            if(elVal.item.stockStatus===1){
              total+=1;
            }
          })
        }
      })
      return total;
    }

    renderRow(elemCS: ModelResult){
      //console.log(elemCS.model + " : " + elemCS.totalAvail);
      if(elemCS.totalAvail===0 && this.state.displayFlags.showAllItems===false){
        return (<TableRow></TableRow>);
      }
      return (
         <>
        <TableRow key={elemCS.model.title}>
        <TableCell component="th" scope="row">
          {elemCS.model.title.replace("Graphics Card","")}<p/>
          <span style={{fontSize:'12px', color:'gray'}}><i>{elemCS.model.modelName}</i></span>
          
        </TableCell>
        <TableCell >{elemCS.itemResult.map((elemItem) =>(
                                  this.renderResults(elemItem)
                                  ))}
        </TableCell>
        <TableCell >{elemCS.itemResult.map((elemItem) =>(
                                  <p style={{marginTop:'10px'}}>
                                  {elemItem.item.lastprice>0?"$"+elemItem.item.lastprice:("N/A")}
                                  </p>
                                  ))}
        </TableCell>
        <TableCell align="center">
        {elemCS.itemResult.map((elemItem) =>(
                                  <>
                                  {elemItem.item.stockStatus===0?(<p style={{marginTop:'10px'}}>out of stock</p>):
                                  (<Button variant="contained" color='secondary' style={{marginTop:'5px'}} href={elemItem.item.link} >Buy</Button>)}
                                  </>
                                  ))}
        </TableCell>
      </TableRow>
      </>                          
       )
    }
    renderCollapse(elem:ChipsetData){
      if(elem.displayFlag){
        return (
          <ExpandLessIcon style={{marginTop:'10px',marginRight:'10px'}}/>
        )
      }else{
        return (
          <ExpandMoreIcon style={{marginTop:'10px',marginRight:'10px'}}/>
        )
      }
    }

    handleNotificationChange(enabled:boolean, elem:ChipsetData){
      console.dir(elem);
      this.state.chipsetArray[elem.idx].notificationEnabled = !elem.notificationEnabled;
      this.setState({chipsetArray:this.state.chipsetArray})
      // update the backend
      var alertConds:AlertCondition[]=[{
        mainValue:elem.chipSetName,
        mainType:"chipset" ,
        maxPrice:-1,
        enabled:enabled
      }]

      this.setAlertCondition(alertConds)
    }

    renderChipsetCategory(elem:ChipsetData){
      let models = Array.from( elem.modelResults.values() );
      let totAvail = this.getTotalAvailable(elem.modelResults);
/* 
        <Collapse in={elem.displayFlag} timeout="auto" unmountOnExit>        
        </Collapse>
        <span>
                  <Checkbox
                          checked={elem.displayFlag}
                          onChange={e => {
                            console.log(e.target.checked);
                            this.state.chipsetArray[elem.idx].displayFlag = !elem.displayFlag;
                            this.setState({chipsetArray:this.state.chipsetArray})
                            //this.setState({ displayFlags:{showAllTypes:e.target.checked, showAllItems:displayFlags.showAllItems}});
                          }}                
                        />
        </span>

*/      
      if(elem.chipSetName.startsWith("?")||elem.chipProducer.startsWith("?")){
        return ("");
      }
      if(this.state.displayFlags.showAllTypes===false && elem.notificationEnabled===false){
        return ("");
      }
      return (
      <div key={elem.idx}>
        <br/>
        {elem.idx>0?(<p/>):""}

        <span style={{display:'inline-block',width:'160px',fontSize:'20px'}}><strong>{elem.chipSetName}</strong></span>
        <span style={{display:'inline-block',width:'90px',fontSize:'20px'}}><strong>{elem.chipProducer}</strong></span>
        <span style={{display:'inline-block',width:'50px',fontSize:'17px',textAlign:'right'}}>{totAvail} &nbsp;/</span>
        <span style={{display:'inline-block',width:'30px',fontSize:'17px',textAlign:'right'}}>{elem.modelResults.size}</span>
        <span style={{display:'inline-block',width:'200px',fontSize:'15px',textAlign:'right'}}>
          {
            elem.maxPrice > 0?
               (elem.minPrice === elem.maxPrice ? elem.minPrice : "$"+elem.minPrice+" - $" + elem.maxPrice):
               "N/A"
            }
        </span>

        <FormControlLabel style={{display:'inline-block',marginLeft:'20px'}}
          control={<Switch checked={elem.notificationEnabled} onChange={e=>{this.handleNotificationChange(e.target.checked, this.state.chipsetArray[elem.idx])}} name={""+elem.idx} />}
          label="Notify"
        />

                  <TableContainer component={Paper}>
                    <Table  aria-label="simple table">
                    <colgroup>
                        <col style={{width:'70%'}}/>
                        <col style={{width:'10%'}}/>
                        <col style={{width:'10%'}}/>
                        <col style={{width:'10%'}}/>
                    </colgroup>
                      {
                        (elem.idx >= 0 && this.state.displayFlags.showAllItems)?(
                          <TableHead>
                          <TableRow>
                            <TableCell><strong>Description</strong></TableCell>
                            <TableCell><strong>Site</strong></TableCell>
                            <TableCell><strong>Price</strong></TableCell>
                            <TableCell align="center"><strong>Status</strong></TableCell>
                          </TableRow>
                        </TableHead>
                        ):<TableHead></TableHead>
                      }
                      <TableBody>
                        {
                        models.map((elemCS) => 
                            (this.renderRow(elemCS))
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
        <>
        </>
      </div>);
    }

    async logout(){
      const { history } = this.props;
      try {
        await Auth.signOut({ global: true }).then(() => {
          localStorage.removeItem(AUTH_USER_TOKEN_KEY);
          history.push('/login');
        });
      } catch (err) {
        //notification.error({ message: err.message });
        history.push('/login');
      }
    }

    render(){
        if(!this.state.data.config){
          return  <h4> Loading </h4>
        }
        
        console.log("Render all items")//this.state.chipsetArray);
        let displayFlags = this.state.displayFlags;
        return (  
        <Layout className="cover" id="app-header">
        <Layout>
          <Layout.Header style={{ background: colors.white, padding: 0 }}>
            <div style={{float: "right"}}>
            <Tooltip title="Logout" placement="bottom-start">
              <IconButton onClick={() => { this.logout() }}>
                <ExitToAppIcon style={{float: "right", width:'30px', height:'30px'}}/>
              </IconButton>
              </Tooltip>
            </div>
            <div style={{float: "right",marginRight:'20px'}}>
            <h1>{this.state.username}</h1>
            </div>
          </Layout.Header>
          <Layout.Content
            style={{
              marginTop: '24px',
              padding: 24,
              background: colors.white,
              minHeight: 280
            }}
          >

            <VendorsHeader sitesConfig={this.state.data.config} timestampRefresh={this.state.timestampRefresh}/>
            
            <div style={{display: 'flex'}}>
               <div style={{width: '0px'}}>                  
               </div>
               <br/>
               <div style={{flexGrow:1}}>            
               <div style={{float: 'left'}}>
               <FormControlLabel
               control={
               <Checkbox
                checked={this.state.displayFlags.showAllTypes}
                onChange={e => {
                  //console.log(e.target.checked);
                  this.setState({ displayFlags:{showAllTypes:e.target.checked, showAllItems:displayFlags.showAllItems}});
                }}                
              />}
              label="Show all"
              />                
              </div>
              <div style={{float: 'right'}}>
              <FormControlLabel
              control={
              <Checkbox
                checked={this.state.displayFlags.showAllItems}
                onChange={e => {
                  //console.log(e.target.checked);
                  this.setState({ displayFlags:{showAllItems:e.target.checked, showAllTypes:displayFlags.showAllTypes}});
                }}                
              />}
              label="Expand all"
              />                
              </div>
              <br/>
              <br/>
              <>
                    {this.state.chipsetArray.map((elemCS) => (
                      elemCS.modelResults.size>0?
                          this.renderChipsetCategory(elemCS):""                        
                    ))}
              </>
         </div>
         </div>

         </Layout.Content>
         </Layout>
        </Layout>
        )
    }
}