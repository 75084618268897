import React from 'react'
import {SiteConfig} from './SiteConfig';
import mqttCommunicator, {AlertListener, getGlobalMQTTtopic} from '../../Utils/MQTTcommunicator';

type MyState = {
  timestampRefresh:Date,
  config:SiteConfig[]
}

type MyProps = {
  sitesConfig:SiteConfig[],
  timestampRefresh:Date
}

export class VendorsHeader extends React.Component<MyProps, MyState>{

    intervalId : number;
    globalTopic : string;

    constructor(props:MyProps) {
      super(props);
      this.state = {
        timestampRefresh:props.timestampRefresh,
        config : props.sitesConfig
       }
       this.globalTopic = getGlobalMQTTtopic();
       this.intervalId = -1;
       this.refreshData = this.refreshData.bind(this);       
    }

    componentDidMount() {
      const that = this
      this.intervalId = setInterval(this.refreshData, 3000);

      mqttCommunicator.registerListener("vendorsHeader", new class extends AlertListener{
        notify(topic:string, payload:string){
          //console.log("Message on: "+topic);
          if(topic !== that.globalTopic){
            return;
          }
          that.processRefreshStatus(payload)
        }
      });
    }
      
    refreshData(){
      try {
        this.setState({
          timestampRefresh:new Date(),
          config:this.state.config
       });
      } catch (e) {
      }
    }

    componentWillUnmount(){
      if(this.intervalId !== -1){ 
        clearInterval(this.intervalId);
      }
      mqttCommunicator.unregisterListener("vendorsHeader");
    }

    processRefreshStatus(payload: string){
      var obj = JSON.parse(payload);
      //console.dir(obj);
      var newConfig = [...this.state.config];
      let crtDate = new Date();
      // find the config that changed
      newConfig.map((elem) => {
        if(elem.siteName === obj.siteName){
          elem.status = obj.status;
          let lastRefreshTime=new Date(obj.timestamp);
          elem.lastRefresh = lastRefreshTime.toString();
          //let tmpDelta=Math.trunc((crtDate.getTime() - lastRefreshTime.getTime())/1000);
          //console.log(obj.siteName + " delta:" + tmpDelta+ ":"+elem.lastRefresh+" vs. " +crtDate);
        }
        return 0;
      })
      
      this.setState({
        timestampRefresh:crtDate,
        config:newConfig
     });
     
    }

    getTimeDelta(tdif: number){
        //console.log(tdif);
        let hours = Math.trunc(tdif/(3600)); // hours
        let mins = Math.trunc((tdif-hours*3600)/60); // minutes
        let secs = tdif - hours*3600 - mins*60; // seconds
        if(hours>0){
          return hours+"h " + mins + "m ";
        }else if(mins>0){
          return mins + "m " + secs +"s";
        }else{
          return secs + "s"
        }
      }
      
      getUpdateStringColor(siteConfig:SiteConfig, tdifSecs:number){
        if(siteConfig.status===0){
          return '#C0C0C0';
        }else if(siteConfig.status===-1){
          return 'red';
        }else{
          var res:string[]=siteConfig.refreshPolicy.split('-');
          var maxRefresh:number = parseInt(res[res.length-1],10);
          if(tdifSecs>4*maxRefresh){
            return 'red';
          }
          return 'green';
        }
      }
  
    renderSiteInfo(siteConfig:SiteConfig){
        let color = 'black';
        let dateStr='';
        //console.log("Lastrefresh_str: " + siteConfig.lastRefresh +" crtitme:" + this.state.timestampRefresh);
        if(siteConfig.lastRefresh){
          let dateRefresh2 = new Date(siteConfig.lastRefresh);
          //console.log("Lastrefresh_date: " + dateRefresh2);
          var tdif=Math.trunc((this.state.timestampRefresh.getTime()-dateRefresh2.getTime())/1000);
          dateStr = this.getTimeDelta(tdif) + " ago" ;
          //console.log("site:" + siteConfig.siteName + " "  + dateRefresh2 + "  : " + this.state.timestampRefresh  + " datestr="+dateStr);
          color=this.getUpdateStringColor(siteConfig, tdif);  
        }
        return (
          <div style={{width: '140px',display: 'inline-block'}} key={siteConfig.id+".conf"}>
             <a href={siteConfig.siteBaseURL}><img src={"crazy/"+siteConfig.siteName+".jpg"} style={{width:'132px',height:'70px', marginLeft: '10px'}}></img></a>   
        <p style={{marginLeft: '10px',color:color, width:'132px', textAlign:'center'}}><i>{dateStr}</i></p>                   
          </div>
        )
      }
  
    render(){
        //console.log("Render sites info")
        return (  
            <>
            <div style={{flexGrow:1}}>            
            <h3>Vendors</h3>
            <div style={{width: '100%',margin: '0 auto'}}>
                  {this.state.config.map((elem) => (
                    this.renderSiteInfo(elem)
                  ))}
            </div>
            </div>
            </>
        )
    }
}