import React from 'react'
import ReactDOM from "react-dom";
import {startViewer, stopViewer} from './viewer'
import {Sensor} from '../../Data/Devices'
import Button from '@material-ui/core/Button';

interface IVideoViewerProps{
    param:Sensor
}

interface IVideoViewerState{
    param:Sensor,
    videoElement:any,
    isVideoActive:boolean,
    isStreamInitialized:boolean
}
  
export class VideoViewer extends React.Component<IVideoViewerProps, IVideoViewerState> {
    videoViewerState:any;

    constructor(props: IVideoViewerProps) {
        super(props);
        //console.log("VideoViewer:");
        //console.dir(props.param);
        this.state={
            param:props.param,
            videoElement:null,
            isVideoActive:false,
            isStreamInitialized:false
        }
        this.videoViewerState = {}
    }

    getFormValues() {
        return {
            region: "us-east-1",
            channelName: this.state.param.channel,
            clientId: this.getRandomClientId(),
            sendVideo: false,
            sendAudio: false,
            openDataChannel: true,
            widescreen: true,
            fullscreen: false,
            useTrickleICE: true,
            natTraversalDisabled: false,
            forceTURN: false,
            endpoint: null,
            sessionToken: null,
        };
    }

    getRandomClientId() {
        return Math.random()
            .toString(36)
            .substring(2)
            .toUpperCase();
    }
    
    videoStreamListener(state:string, terminated:boolean){
        //console.log("LC:" + state);
        this.setState({isVideoActive:!terminated});
    }

    componentDidMount() {
        let videoElement = ReactDOM.findDOMNode(this);
   
        this.videoViewerState['localView']=null;
        this.videoViewerState['remoteView']=videoElement;
        //startViewer(this.videoViewerState, this.getFormValues(),null, this.videoStreamListener.bind(this));
    }
    componentWillUnmount(){
        console.log("stopViewer");
        stopViewer(this.videoViewerState);
    }
    render() {
        const internal_id=this.state.param.device_id+"."+this.state.param.id;
        const formValues = this.getFormValues();

        return(
        <> 
        <video key={"v"+this.state.param} width="100%" height="100%" style={{position:"absolute"}} className={"remote-view."+internal_id} autoPlay playsInline controls >
        </video>
        {(!this.state.isVideoActive)&&
            <Button className='item__video_refresh'
                variant="contained" color="primary"                
                onClick={() => {
                    if(!this.state.isStreamInitialized){
                        //startViewer(this.videoViewerState, this.getFormValues(),null, this.videoStreamListener.bind(this));                        
                    }
                    this.setState({isVideoActive:!this.state.isVideoActive,isStreamInitialized:true});
                    let node = ReactDOM.findDOMNode(this);
                    this.videoViewerState['localView']=null;
                    this.videoViewerState['remoteView']=node;            
                    startViewer(this.videoViewerState, this.getFormValues(),null, this.videoStreamListener.bind(this));
                }}
            >Connect</Button>
        }        
        
        </>
      )
    }
  
  }