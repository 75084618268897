/** Auth user token key */

export const crazy_actions = Object.freeze({
    CRAZY_ACTION_GET_DATA :  'hard2buy.getdata',    
    CRAZY_ACTION_REFRESH_DATA: 'hard2buy.refresh',
    CRAZY_ACTION_SET_ALERT:    'hard2buy.setAlert'
});

export const filter_types = Object.freeze({
    CHIPSET :  'chipset',
});
