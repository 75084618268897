import React from 'react'
import {SingleValueViewer} from './SingleValueViewer';
import {VideoViewerContainer} from './VideoViewerContainer';
import GPSViewer   from './GPSViewer'; 
import {Sensor,DeviceType} from '../../../Data/Devices'


interface SensorViewerParams{
    type: string,
    data: any[],
    device:DeviceType,
    sensor:Sensor
}

export default class SensorViewer extends React.Component<SensorViewerParams>{


    render(){
        if(this.props.type==='Video'){
            return(<VideoViewerContainer sensor={this.props.sensor} device={this.props.device}/>);
        }
        else if(this.props.type==='GPS'){
            return(<GPSViewer data={this.props.data} />);
        }
        else if(this.props.type==='Temp'){
            return(
              <div className="chart-container" style={{ width: "100%", height: "100%" }}>
                <SingleValueViewer name='Temperature' unit="F" data={this.props.data} />
              </div>
            )
            ;
        }
    }
}