import React from "react";
import _ from "lodash";
import { Responsive, WidthProvider } from "react-grid-layout";
import SensorViewer from "./ViewComponent/SensorViewer"

const ResponsiveReactGridLayout = WidthProvider(Responsive);

export default class GridLayoutComponent extends React.Component {
  constructor(props) {
    super(props);
    const cols = 16

    this.state = {
      currentBreakpoint: "lg",
      compactType: "vertical",
      mounted: false,
      cols : cols,
      rowHeight:80,
      mapPanels: props.mapPanels,
      device:props.device,
      deviceData:props.deviceData,
      //layouts: { lg: this.generateLayout(props.mapPanels, cols) },
      refreshView:true
    };
    //console.log("GridLayoutComponent:", props);
    this.onBreakpointChange = this.onBreakpointChange.bind(this);
    this.onCompactTypeChange = this.onCompactTypeChange.bind(this);
    this.onLayoutChange = this.onLayoutChange.bind(this);
    this.onNewLayout = this.onNewLayout.bind(this);
  }

  getSensorPanelWidth(val){
    if(val==='Video'){
      return 6;
    }else if(val === 'GPS'){
      return 6;
    }
    // else
    return 4
  }

  generateLayout(mapPanels, cols) {
    //console.log("GridLayoutComponent:generateLayout:",mapPanels);
    var hight= 4;
    var objOut2=[];
    var xPos=0;
    var yPos=0;
    for (const [key, value] of Object.entries(mapPanels)) {
      //var arr = value.sensors;      
      value.sensors.forEach(sens =>{
        var width = this.getSensorPanelWidth(value.type);        
        //console.log("add sensor data: ", sens.data);
        objOut2.push({
          x: xPos,
          y: yPos,
          w: width,
          h: hight,
          i: value.type+":"+sens.id,
          t: value.type,
          d: sens.data,
          s: sens,
          static: false
        })
        xPos+=width;
        if(xPos>=cols){
          xPos=0;
          yPos++;
        }
      })
    };
    //console.dir(objOut2);
    return objOut2;
  }

  componentDidMount() {
    this.setState({ mounted: true });
  }

  generateDOM(layouts) {
    /*
    if(this.state.mounted==false){
      return("Loading")
    }
    */
    const device = this.state.device;
    //console.dir(this.state.device);
    
    return _.map(layouts.lg, function(l, i) {
      return (
        <div key={l.i} className={l.static ? "static" : ""}>
          {l.static ? (
            <span
              className="text"
              title="This item is static and cannot be removed or resized."
            >
              Static - {i}
            </span>
          ) : (
            
            <div className="chart-container" style={{ width: "100%", height: "100%" }}>
              <SensorViewer type={l.t} data={l.d} sensor={l.s} device={device}/>
            </div>
            
          )}
        </div>
      );
    });
  }

  onBreakpointChange(breakpoint) {
    this.setState({
      currentBreakpoint: breakpoint
    });
  }

  onCompactTypeChange() {
    const { compactType: oldCompactType } = this.state;
    const compactType =
      oldCompactType === "horizontal"
        ? "vertical"
        : oldCompactType === "vertical"
          ? null
          : "horizontal";
    this.setState({ compactType });
  }

  onLayoutChange(layout, layouts) {
    //console.log("GridLayoutComponent:onLayoutChange", layout, layouts);
    this.props.onLayoutChange(layout, layouts);
  }

  onNewLayout() {
    this.setState({
      refreshView:true,
    });
  }

  render() {
    //console.log("GridLayoutComponent:render");
    let layouts = { lg: this.generateLayout(this.props.mapPanels, this.state.cols) }
    return (
      <div>
        <ResponsiveReactGridLayout
          {...this.props}
          rowHeight={this.state.rowHeight}
          className={"layout"}
          cols={{ lg: this.state.cols, md: 10, sm: 6, xs: 4, xxs: 2 }}
          layouts={layouts}
          onBreakpointChange={this.onBreakpointChange}
          onLayoutChange={this.onLayoutChange}
          // WidthProvider option
          measureBeforeMount={false}
          // I like to have it animate on mount. If you don't, delete `useCSSTransforms` (it's default `true`)
          // and set `measureBeforeMount={true}`.
          useCSSTransforms={this.state.mounted}
          compactType={this.state.compactType}
          preventCollision={!this.state.compactType}
          isDraggable={true}
          draggableHandle=".item__title"
        >
          {this.generateDOM(layouts)}
        </ResponsiveReactGridLayout>
      </div>
    );
  }
}




