import axios from 'axios';
import { API_AUTH_TOKEN } from './constants';
import {API_URL} from '../env';
import { notification } from 'antd';

const jwtDecode = require('jwt-decode');

/**
 * helper method to validate  user token
 *
 * @param {*} token
 * @returns {boolean}
 */
export const validateToken = (token: any): boolean => {
  if (!token) {
    return false;
  }
  //console.log("call backend url: " + API_URL);
 
  try {
    const decodedJwt: any = jwtDecode(token);
    //console.dir(decodedJwt);
    return decodedJwt.exp >= Date.now() / 1000;
  } catch (e) {
      console.log("exception decoding token:" + e);
    return false;
  }
};

export default {   
  backendCall: (params: any) => 
      axios({
        url: API_URL,
        method: 'POST',
        headers: {
          'Authorization':localStorage.getItem(API_AUTH_TOKEN),
          'Content-Type': 'application/json'
        },
        data:params
      })
    ,

    errorAlert: (error:any) =>{
      notification.error({
        message: 'Error',
        description: (error.response?error.response.data.error.message:"Unable to reach server"),
        placement: 'topRight'
      })
    },

    getError:(error:any, defaultMessage:string) =>{
      if (error && error.response && error.response.data && error.response.data.error){
        return error.response.data.error.message
      }
      return defaultMessage
    },
}


export class UnitsHelper{
  static getDistanceUnit(){
    return "Miles";
  } 

  static formatDistance(meters: Number){
    let miles = (Number(meters) / Number(1609.34)).toFixed(2);
    return ""+miles;  
  }
}