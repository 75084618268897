import React, { Component} from 'react'

import {DeviceType, GPSDeviceData, GPSSessionData,SensorData} from '../Data/Devices'

import GoogleMapReact from 'google-map-react';
import {GOOGLE_API_KEY} from './constants'

export interface GPSRendererState{
    mapData:any[],
    map:any,
    maps:any;
}

type GPSRendererProps = typeof GPSRenderer.defaultProps & {
    sessions:GPSSessionData[],
    manageState?:boolean,
    showControls?:boolean
}

export class GPSRenderer extends Component<GPSRendererProps>{
    static defaultProps = {        
        manageState:false,
        showControls:false
    };

    gpsState : GPSRendererState|null = null;

    
    private renderPolyline(crtPolylinePoints:any, map:any, maps: any){
        let geodesicPolyline = new maps.Polyline({
            path: crtPolylinePoints,
            geodesic: false,
            strokeColor: '#3A45CD',
            strokeOpacity: 1,
            strokeWeight: 5
        })
        geodesicPolyline.setMap(map);
        if(this.gpsState){this.gpsState.mapData.push(geodesicPolyline)};
        let markerStop = new maps.Marker({
            position: { lat: crtPolylinePoints[0].lat, lng: crtPolylinePoints[0].lng },
            title: 'Stop',
            icon: {
                url: "https://maps.google.com/mapfiles/ms/icons/red-dot.png"
              }
            });
        markerStop.setMap(map);
        if(this.gpsState){this.gpsState.mapData.push(markerStop)}
        let markerStart = new maps.Marker({
            position: { lat: crtPolylinePoints[crtPolylinePoints.length-1].lat, lng: crtPolylinePoints[crtPolylinePoints.length-1].lng },
            title: 'Start',
            icon: {
                url: "https://maps.google.com/mapfiles/ms/icons/green-dot.png"
              }
            });
        markerStart.setMap(map);
        if(this.gpsState){this.gpsState.mapData.push(markerStart)}
    }

    private fitBounds (map:any, maps:any, markers:any) {
        var bounds = new maps.LatLngBounds()
        for (let marker of markers) {
          bounds.extend(
            new maps.LatLng(marker.lat, marker.lng)
          )
        }
        map.fitBounds(bounds)
    }
    
    private clearMap(){
        if(!this.gpsState){
            return;
        }
        this.gpsState.mapData.forEach(x=>{
            x.setMap(null);
        })
        this.gpsState.mapData=[]
    }

    setState(map:any, maps:any){        
        this.gpsState = {mapData : new Array(0), map:map, maps:maps};        
    }

    renderSessions(map:any, maps:any, sessions:GPSSessionData[]) {
        if(this.gpsState){this.clearMap()}
        if(!sessions || sessions.length===0){
            return;
        }
        let someCoords:any = [];

        sessions.forEach(session=>{
            let crtPolylinePoints:any = [];
            session.points.forEach(point=>{
                let crtCoords={"lat":point.vals[0], "lng":point.vals[1]};
                crtPolylinePoints.push(crtCoords);
                someCoords.push(crtCoords);
            })
            this.renderPolyline(crtPolylinePoints, map, maps);
        })

        // fit the bounds
        this.fitBounds(map, maps,someCoords)        
    }

    updateSessions(sessions:GPSSessionData[]){
        if(!this.gpsState){
            return;
        }
        this.renderSessions(this.gpsState.map, this.gpsState.maps, sessions);
    }

    render(){
        return(
            <>
            <GoogleMapReact
                defaultZoom={12}
                bootstrapURLKeys={{ key: GOOGLE_API_KEY }}
                defaultCenter={{ lat: 53.480759, lng: -122.176}}
                yesIWantToUseGoogleMapApiInternals={true}    
                options={{
                    fullscreenControl: this.props.showControls,
                    zoomControl:this.props.showControls,
                    panControl:this.props.showControls,
                    disableDefaultUI:!this.props.showControls,
                    keyboardShortcuts:this.props.showControls
                }}
                onGoogleApiLoaded={({map, maps}) => {
                    if(this.props.manageState){this.setState(map, maps)}; 
                    this.renderSessions(map, maps, this.props.sessions)}
                }
                >
            </GoogleMapReact>
            {this.props.manageState?this.updateSessions(this.props.sessions):""}
            </>
        );
    }
}

